import { Contentful } from '@lib'
import { renderRichText } from '@utils'
import { InferGetStaticPropsType } from 'next'
import Head from 'next/head'
import { Nav } from '../components'
import { Footer } from '../components/Footer'

export async function getServerSideProps() {
  return {
    props: await Contentful.homepage(),
  }
}

export default function Home(props: InferGetStaticPropsType<typeof getServerSideProps>) {
  const { headerTitle, paragraph1, work1, work2, work3, paragraph2, partners, cta } = props

  return (
    <div className='min-h-screen flex flex-col overflow-hidden'>
      <Head>
        <title>Alchemy</title>
        <link rel='icon' href='/favicon.ico' />
        <meta httpEquiv='Content-type' content='text/html; charset=UTF-8' />
        {/* ReCaptcha Script */}
        {/* <script src='https://code.jquery.com/jquery-1.12.4.js'></script>
        <script src='https://code.jquery.com/ui/1.12.1/jquery-ui.js'></script>
        <script src='https://www.google.com/recaptcha/api.js' async defer />
        <script
          type='text/javascript'
          dangerouslySetInnerHTML={{
            __html: `
            function timestamp() { var response = document.getElementById("g-recaptcha-response"); if (response == null || response.value.trim() == "") {var elems = JSON.parse(document.getElementsByName("captcha_settings")[0].value);elems["ts"] = JSON.stringify(new Date().getTime());document.getElementsByName("captcha_settings")[0].value = JSON.stringify(elems); } } setInterval(timestamp, 500);
          `,
          }}
        />
        <script
          type='text/javascript'
          dangerouslySetInnerHTML={{
            __html: `
            function recaptcha_callback(){
              $('.button').prop("disabled", false);
            }
          `,
          }}
        /> */}
      </Head>

      <Nav />

      <main className='container flex-grow'>
        <header className='mt-10 mb-20'>
          <div className='text-blue font-heading font-bold lg:text-lg text-md leading-none'>
            {renderRichText(headerTitle)}
          </div>
        </header>

        <section className='text-sm flex'>
          <div className='lg:w-2/3 '>{renderRichText(paragraph1)}</div>
        </section>

        <section
          className='my-10 rounded-3xl text-white p-10 text-sm text-center relative'
          style={{
            background: 'linear-gradient(69.6deg, #3792BE 20.3%, #C7327C 241.93%)',
          }}
        >
          <h2 className='text-white mb-10'>How does Alchemy work?</h2>
          <div className='grid xl:grid-cols-3 gap-5 work relative z-10'>
            <div className='flex flex-col text-center items-center justify-center gap-4'>
              <img src='/connect.png' alt='connect' style={{ height: 150 }} />
              {renderRichText(work1)}
            </div>
            <div className='flex flex-col text-center items-center justify-center gap-4'>
              <img src='/exchange.png' alt='exchange' style={{ height: 150 }} />
              {renderRichText(work2)}
            </div>
            <div className='flex flex-col text-center items-center justify-center gap-4'>
              <img src='/contribute.png' alt='contribute' style={{ height: 150 }} />
              {renderRichText(work3)}
            </div>
          </div>

          {/* Background squares */}
          <div
            style={{
              backgroundImage: "url('/squares-right.png')",
              position: 'absolute',
              top: -136,
              right: -200,
              height: 550,
              width: 500,
              backgroundRepeat: 'no-repeat',
            }}
          ></div>
          <div
            style={{
              backgroundImage: "url('/squares-left.png')",
              position: 'absolute',
              bottom: -195,
              left: -225,
              height: 750,
              width: 750,
              backgroundRepeat: 'no-repeat',
            }}
          ></div>
        </section>

        <section className='text-sm flex justify-end'>
          <div className='lg:w-1/2' />
          <div className='lg:w-1/2'>{renderRichText(paragraph2)}</div>
        </section>

        <section>
          <h2>Partners</h2>
          <div className='grid grid-cols-4 gap-5'>
            {partners.map(({ src, alt }) => (
              <div className='flex items-center' key={alt}>
                <img src={src} alt={alt} />
              </div>
            ))}
          </div>
        </section>

        {/* Signup form */}
        {/* <section className='cta flex flex-col items-center'>
          <div className='text-center lg:w-2/3 mb-3'>{renderRichText(cta)}</div>
          <form
            className='lg:w-1/2 shadow-2xl rounded-3xl p-4'
            action='https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8'
            method='POST'
          >
            <input
              type='hidden'
              name='captcha_settings'
              value='{"keyname":"newalchemy","fallback":"true","orgId":"00D0Y000001fr8W","ts":""}'
            />
            <input type='hidden' name='oid' value='00D0Y000001fr8W' />
            <input
              type='hidden'
              name='retURL'
              value='https://iese.org.uk/thanks-alchemy/'
            />
            <input
              type='hidden'
              name='Source__c'
              value='alchemy_register_interest'
            />
            <input
              type='hidden'
              name='Business_Allocation__c'
              value='Digital'
            />

            <label className='w-full'>
              First Name
              <input
                id='first_name'
                maxLength={90}
                name='first_name'
                type='text'
                required
              />
            </label>

            <label className='w-full'>
              Last Name
              <input
                id='last_name'
                maxLength={80}
                name='last_name'
                type='text'
                required
              />
            </label>

            <label className='w-full'>
              Role
              <input
                id='title'
                maxLength={90}
                name='title'
                type='text'
                required
              />
            </label>

            <label className='w-full'>
              Organisation
              <input
                id='company'
                maxLength={40}
                name='company'
                type='text'
                required
              />
            </label>

            <label className='w-full'>
              Email
              <input
                id='email'
                maxLength={80}
                name='email'
                type='email'
                required
              />
            </label>

            <div
              className='g-recaptcha'
              data-sitekey='6Lci66caAAAAAJTcDnkbJfwJhsY7jFW67Bv3ER17'
              data-callback='recaptcha_callback'
            ></div>
            <input
              type='submit'
              value='Submit'
              name='submit'
              id='submitBtn'
              disabled
              className='button'
            />
          </form>
        </section> */}
      </main>

      <Footer />
    </div>
  )
}
